<template>
  <div class="systemInfo wr">
    <div class="info-a wr f-cb">
      <div class="left">
        <div class="pinfo-container wr swiper-container-horizontal swiper-container-android">
        <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-active">
          <img src="../../../img/operatingSystem.jpg" alt="">
        </div>
        </div>
        </div>
        </div>
        <div class="right ">
        <div class="tit-60 mc hnb">人员巡点检智能运维系统</div>
        <div class="tit-18 t1 hnb">
        <p style="line-height: 1.75em; text-align: justify;">
          <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
            人员巡点检智能运维系统通过实时数据监测、智能分析和自动化管理优化供水系统的运维工作。  系统监控关键设备，自动识别设备问题并及时通知运维人员，减少停机时间，保持供水系统高效运作。
          </span>
        </p>
        <p style="line-height: 1.75em;"><br></p>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="info-b wr">
        <div class="tit-48 mc lh1 hnb">产品特点</div>
        <div class="list f-cb">
          <div class="li"> 
            <div>
              <div class="icon">
                <img :src="require('@/img/productFeatures-icon.png')" alt="实时数据监控">
              </div>
              <div class="tit-24 t1 hnb">实时监测与设备健康分析</div>
            </div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统实时采集并展示水流量、压力、水质等关键指标，帮助管理人员随时掌握水务系统的状态。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="预测性维护与决策支持">
            </div>
            <div class="tit-24 t1 hnb">预测性维护与决策支持</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  通过分析历史和实时数据，采用维护算法预测设备故障，减少意外停机维护成本。同时提供数据驱动的决策支持，优化资源分配和维护计划。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="智能报警与紧急响应">
            </div>
            <div class="tit-24 t1 hnb">智能报警与紧急响应</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统检测到关键设备性能下降或异常时，将触发智能报警并通知运维团队紧急响应，最大程度减少对供水服务的影响。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="用户定制化报告与数据分析">
            </div>
            <div class="tit-24 t1 hnb">用户定制化报告与数据分析</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统提供定制化报告和数据分析，帮助运维管理层深入了解供水系统整体状况，做出更为精确有效的决策。
                </span>
              </p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
.mc {
    color: #0041c3;
}
.lh1 {
    line-height: 1;
}
.tit-48 {
    line-height: 1.8;
    font-size: 48px;
}
// web端样式
.systemInfo {
  background-image: url(../../../img/bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // padding-top: 100px;
  padding-bottom: 4.5rem;
  .info-a {
    padding-top: 7.38rem;
    padding-bottom: 6rem;
    padding-left: 9rem;
    .left {
      float: left;
      width: 44.8%;
      .pinfo-container {
          overflow: hidden;
      }
    }
    .right {
        float: left;
        width: 55.2%;
        padding: 32px 190px 0 89px;
        // padding-left: 2.225rem;
        // padding-right: 4.75rem;
        // padding-top: .8rem;
      .mc {
        border-bottom: 1px solid rgba(0, 65, 195, .2);
        line-height: 1;
        padding-bottom: 2.5rem;
        position: relative;
      }
      .mc::after {
        content: "";
        width: 60px;
        height: 6px;
        background: #0041C3;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .t1 {
        color: #000000;
        margin-top: 3.31rem;
      }
    }
  }
  .view {
    width: 100%;
    padding: 0 190px;
    .info-b {
      background-color: #fff;
      padding: 76px 118px;
      margin-top: 20px;
      .list {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .li {
            width: calc(39.5%);
            // width: calc((100% - 115px) / 2);
            margin-right: 115px;
            padding: 40px 0;
            border-bottom: 1px solid rgba(207, 214, 229, 1);
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-bottom: 0.63rem;
            }
            .t1 {
                display: inline-block;
                margin-top: 1.25rem;
                margin-bottom: 1rem;
                margin-left: 0.8rem;
                position: relative;
            }
        }
      }
    }
  }
}
// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>